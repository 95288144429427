import script from "./AdminPage.vue?vue&type=script&setup=true&lang=js"
export * from "./AdminPage.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTh,QTd,QBtn,QMenu,QList,QItem});qInstall(script, 'directives', {ClosePopup});
